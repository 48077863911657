import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageContext } from "../context"

const ProjectPage = ({ location }) => (
  <PageContext.Provider value={{location}} >
    <Layout>
      <SEO title="Projects" />
      <h1>Projects</h1>
    </Layout>
  </PageContext.Provider>
)

export default ProjectPage
